<template>
<div class="ym-login-container">
  <login :title='title'
         :systemTitle="systemTitle"
         :align='align'
         :formStyle='formStyle'
         :options='options'
         :logoOptions='logoOptions'
         :background='background'
         :bottomText='bottomText'
         :forgetPassword="forgetPassword"
         :goRegist="goRegist"
         :canSubmit="agree"
         @onLogin="showlogin"
         @chengeImgVerify="chengeImgVerify"
         @sendPhoneMessage='sendPhoneMessage'
         ref="login"
         >
         <div class="check-wrap" slot="check">
           <span class="icon-wrap" :class="agree? 'active': ''" @click="agree = !agree">
             <i class="el-icon el-icon-success"></i>
           </span>
           <span class="check-text">同意并阅读了<i @click="modalShowFn('共识机制')">《共识机制》</i>和<i @click="modalShowFn('入驻协议')">《入驻协议》</i></span>
         </div>
  </login>
  <modal v-if="modalShow" @hiddenModal="hiddenModal"  @submit="submit" :title="modalTitle" >
      <div slot="content">共识机制</div>
  </modal>
  </div>
</template>

<script>
import login from '../../components/Login'
import modal from '../../components/Login/modal'

// 本地存储工具类
import { _local, _session, setToken } from '../../utils/localStorage'

export default {
  data() {
    return {
      modalTitle: '',
      modalShow: false,
      // 标题,显示在登录表单上方
      title: '登录',
      systemTitle: '兑欢券平台',
      // 登录框位置,可选 left/center/right
      align: 'center',
      // 表单风格, default默认无风格/icon图标风格/label标签风格
      formStyle: 'default',
      // 底部栏信息
      bottomText: '粤公网安备 44011202000383号',
      // 登录页背景,可以为图片,也可以传输颜色, 颜色必须以'#'开头
      background: {
        'background-color': '#F4F5F8',
        'background-image': 'url(' + require('../../components/Login/img/bg_login_img.jpg') + ')',
        'background-size': 'cover'
      },

      // 是否显示表单下面的前往登录链接
      goRegist: false,
      forgetPassword: true,
      agree: true,

      // 左上角logo配置项
      logoOptions: {
        left: '10px',
        logoType: 'img',
        title: 'YIMI蚁米',
        width: '180px',
        url: require('../../components/Layout/img/logo.png')
      },

      // 登录表单项详细配置,不传入则全部采用默认设置
      options: {
        // 账号栏配置,不传入则采用默认账号栏配置
        account: {
          mapping: 'account',
          // 账号栏label名,显示在输入框上方
          label: '用户名',
          // 账号栏左侧图标,需要formStyle为icon
          icon: 'el-icon-user',
          maxLength: 20,
          message: '请输入用户名',
          placeholder: '请输入用户名',
          rule: [
            { message: '请输入用户名', required: true },
            { message: '请输入20以内的数字，字母，中文',
              required: true,
              pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{1,20}$/
            }
          ],
          clearable: true
          // 账号输入框表单校验正则,作为登录操作以及第一次点击登录后的校验依据
          // 开启校验后,若校验未通过,则在输入框下方进行红字提示
        },

        // 密码栏配置,不传入则采用默认密码栏设置
        password: {
          label: '密码',
          icon: 'el-icon-lock',
          maxLength: 16,
          message: '请输入密码',
          placeholder: '请输入密码',
          clearable: true,
          rule: [
            { message: '请输入密码', required: true },
            { message: '6到16位（字母，数字组合）', pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/, required: true }
          ]
        }
      }
    }
  },
  components: {
    login, modal
  },
  methods: {
    // 执行登录操作,并将回调的账号保存在本地
    showlogin(account, password, phoneVerify, imgVerify) {
      if (!this.agree) {
        this.$message({
          type: 'warning',
          message: '请勾选协议'
        })
        return false
      }
      this.$http.user.login({
        account, password
      }).then(res => {
        if (res.data.code === 0) {
          _local.set('hasInit', 1)
          _local.set('userName', account)
          setToken(res.data.data.sessionId)
          _session.set('hasInit', 1)
          this.getRouters()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          })
          this.$refs.login.resetButton()
        }
      }).catch(err => {
        this.$message({
          message: err,
          type: 'error'
        })
      })
    },

    // 更换验证码回调方法,传入图片路径即可更换验证码
    chengeImgVerify() {
      console.log('更换验证码')
      this.options.imgVerify.url = 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1114443007,4076857238&fm=26&gp=0.jpg'
    },
    // 发送手机验证码事件
    sendPhoneMessage() {
      console.log('发送验证码')
    },
    hiddenModal () {
      this.modalShow = false
    },
    submit () {
      this.modalShow = false
    },
    modalShowFn (str) {
      this.modalTitle = str
      this.modalShow = !this.modalShow
    },
    getRouters () {
      this.$http.user.getUserMenu().then(res => {
        if (res.data.code === 0) {
          _local.set('router', res.data.data)
          setTimeout(() => {
            this.$router.push({ name: res.data.data[0].pathName })
          }, 0)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.ym-login-container{
  width:100%;
  height: 100%;
}

.check-wrap{
  width: 70%;
  margin: 15px 15%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  .check-text{
    font-size: $--font-size-extra-small;
    color: $--color-text-primary;
    i {
      font-style: normal;
      color: $--color-primary;
      cursor: pointer;
    }
  }
  .icon-wrap{
    width: 16px;
    height:16px;
    border-radius: 50%;
    color: $--icon-color2;
    margin-right: 8px;
    cursor: pointer;
     i{
       font-size:16px;
     }
  }
  .active{
    color: $--color-primary;
  }
}

</style>
