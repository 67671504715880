<template>
 <el-dialog
  :title="title"
  width="80%"
  :visible="visible"
  center
  :append-to-body="true"
  @close="hiddenModal"
  >
  <slot name="content"></slot>
  <div slot="footer" class="dialog-footer">
    <el-button @click="hiddenModal">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </div>
</el-dialog>
</template>

<script>
export default {
  props: {
    title: String
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    hiddenModal () {
      this.visible = false
      this.$emit('hiddenModal')
    },
    submit () {
      this.visible = false
      this.$emit('submit')
    }
  },
  mounted () {
    this.visible = true
  }
}

</script>

<style>
.dialog-footer{
  text-align:right
}
</style>
